.about-company_row {
  & .about-company {
    &__content {
      display: grid;
      align-items: stretch;
      justify-content: stretch;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--grid-column-gap);

      @include mediaTablet {
        grid-template-columns: 1fr;
        gap: rem(45);
      }

      @include mediaMobile {
        gap: rem(35);
      }
    }

    &__wrapper {
      grid-column: 2/3;
      grid-row: 1/1;

      padding: 0;
      padding-right: rem(150);
      justify-content: center;

      @include mediaBigDesktop {
        padding-right: big(150);
      }

      @include mediaTablet {
        grid-column: 1/2;
        grid-row: 2;
      }

      @include mediaTablet {
        padding-right: 0;
      }
    }

    &__text {
      @include mediaLaptop {
        gap: rem(40);
      }

      @include mediaTablet {
        gap: rem(25);
      }

      @include mediaMobile {
        gap: rem(15);
      }
    }

    &__image {
      position: relative;
      right: 0;

      grid-column: 1/2;
      grid-row: 1/1;

      width: 100%;
      height: auto;
      min-height: rem(800);

      @include mediaBigDesktop {
        min-height: big(800);
      }

      @include mediaLaptop {
        min-height: rem(645);
      }

      @include mediaTablet {
        grid-column: 1/2;
        grid-row: 1;

        height: rem(500);
        min-height: unset;
      }

      @include mediaMobile {
        height: rem(280);
      }

      & img,
      & picture {
        position: absolute;
        top: 0;
        left: 0;

        width: calc(100% - #{rem(40)});
        height: 100%;

        @include mediaBigDesktop {
          width: calc(100% - #{big(40)});
        }

        @include mediaTablet {
          width: 100%;
        }
      }

      & picture img {
        width: 100%;
      }
    }
  }
}
