.about-company.about-company_reverse {

  & .about-company {
    &__wrapper {
      grid-column: 1/2;
    }
    &__image {
      grid-column: 2/3;

      @include mediaTablet {
        grid-column: 1/2;
      }
    }
  }
}
