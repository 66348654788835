@import '../../mixins';

.about-company {
  &__content {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(80);

    @include mediaBigDesktop {
      gap: big(80);
    }

    @include mediaTablet {
      gap: rem(50);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(50);

    @include mediaBigDesktop {
      gap: big(50);
    }

    @include mediaLaptop {
      gap: rem(40);
    }

    @include mediaTablet {
      gap: rem(30);
    }

    @include mediaMobile {
      gap: rem(20);
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaLaptop {
      gap: rem(30);
    }

    @include mediaTablet {
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__subtitle {
    color: var(--text-dark-primary);
  }

  &__article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaTablet {
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__more {
    @include mediaMobile {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: rem(880);

    border-radius: var(--radius-common);
    overflow: hidden;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      height: big(880);
    }

    @include mediaLaptop {
      height: rem(660);
    }

    @include mediaTablet {
      position: relative;
      right: var(--container-offset);

      width: calc(100% + (var(--container-offset) * 2));
    }

    @include media(#{rem(990)}) {
      height: rem(386);
    }

    @include media(#{rem(420)}) {
      height: rem(220);
    }

    & img,
    & picture {
      width: 100%;
      height: 100%;

      background-color: var(--bg-light-extra);
      object-fit: cover;
      object-position: center;
    }
  }
}

@import "type-2", "reverse";
